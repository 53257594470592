import React from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Grid, Box, Typography, makeStyles, Button } from "@c2fo/components__deprecated"
import { Header } from "../components/Header/Header"
import Reveal from "../components/reveal"
import SEO from "../components/Seo";
import { useLocation } from "@reach/router"
import { graphql } from 'gatsby'


const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(5, 2.5),
  },
  buttonLogin: {
    backgroundColor: `#06A76C`,
    "&:hover": {
      backgroundColor: `#058556`,
    },
    borderRadius: '2.8125rem',
    textTransform: "none"
  }

}))

export default function Thankyou({pageContext}) {
  const { language } = pageContext;
  const location = useLocation()
  const { t } = useTranslation();
  const classes = useStyles()

  if (typeof window !== "undefined" && window._satellite) {
    const historyState = window.history.state.selectedBuyers;
    window.c2foData.digitalData.divlinkselect = historyState.join("|");
    window.c2foData.digitalData.pageTitle = t('confirmation.seo.title');
    window._satellite.track("divisionLinkSuccess");
  }

  return (
    <>
      <SEO language={language} title={t('index.seo.title')} description={t('index.seo.title')} />
      <Reveal />
      <Box>
        <Header loginText={t('confirmation.login')} queryString={location.search}/>
      </Box>
      <Grid container alignItems="center" direction="column">
        <Grid item md={8} className={classes.container}>
          <Box pt={5}>
            <Typography variant="h4" align="center">
                {t('confirmation.heading')}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button className={classes.buttonLogin} size="large" href="https://app.c2fo.com/login">
          {t('confirmation.login')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
